.bio-heading {
  color: var(--brand-color);
  font-family: var(--roboto);
  font-weight: 500;
}

.bio-text {
  font-family: var(--roboto);
}

.bio-link,
.bio-link:visited {
  color: var(--brand-color);
}

.bio-link:hover {
  color: var(--brand-dark);
  text-decoration: none;
}

.contact-details {
  background-color: var(--light-color);
  color: var(--dark-color);
  box-shadow: 0px 3px 15px rgba(0,0,0,0.1);
}

.contact-heading {
  color: var(--accent-dark);
  font-family: var(--rubik);
  font-weight: 500;
}

.social-icon,
.social-icon:visited {
  display: inline-block;
  color: var(--brand-color);
  font-size: 2rem;
}

.social-icon:hover {
  color: var(--brand-dark);
}