nav {
  background-color: white;
  box-shadow: 0px 3px 15px rgba(0,0,0,0.1);
  flex: none;
}

.initials,
.initials:hover,
.initials:visited {
  color: var(--brand-color);
  font-family: var(--rubik);
  font-size: 1rem;
  font-weight: 900;
  letter-spacing: 2px;
}

.text-link,
.text-link:visited {
  color: var(--dark-color);
  display: inline-block;
  font-family: var(--roboto);
  font-size: 1rem;
  font-weight: 600;
  letter-spacing: 2px;
}

.text-link:hover {
  color: var(--brand-color);
  text-decoration: none;
}

.icon {
  color: var(--dark-color);
  font-size: 2rem;
}

.icon-toggler:hover {
  color: var(--brand-color);
}