.card {
  background-color: var(--light-color);
}

.project-title {
  color: var(--brand-color);
}

.stack-badges {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.tech-badge {
  background-color: var(--brand-dark);
  color: var(--light-color);
  font-size: .75rem;
  font-weight: 300;
}

.project-description {
  color: var(--dark-color);
  font-size: 0.75rem;
}

.project-btn {
  background-color: var(--brand-color);
  color: var(--light-color);
  font-size: 0.75rem;
  box-shadow: 0px 3px 15px rgba(0,0,0,0.2);
}

.project-btn:hover {
  background-color: var(--brand-dark);
  color: var(--light-color);
  font-size: 0.75rem;
}