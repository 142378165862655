#header {
  background-color: var(--light-color);
}

#header-text {
  max-width: 90%;
}

.heading {
  color: var(--dark-color);
  font-family: "Rubik";
  font-size: 5rem;
}

#header-icon {
  color: var(--brand-color);
}

#img-wrapper {
  border-radius: 50%;
  border: 4px solid var(--brand-color);
  width: 200px;
  height: 200px;
  overflow: hidden;
  position: relative;
}

#img-wrapper img {
  height: 105%;
  width: auto;
  margin: auto;
}

.lead {
  max-width: 600px;
  line-height: 1.5;
}

.cta-primary {
  background-color: var(--brand-color);
  color: var(--light-color);
}

.cta-primary:hover {
  background-color: var(--brand-dark);
  color: var(--light-color);
}

.cta-secondary {
  background-color: var(--brand-color);
  color: var(--light-color);
}

.cta-secondary:hover {
  background-color: var(--brand-dark);
  color: var(--light-color);
}

