footer {
  background-color: var(--accent-dark);
  flex: none;
}

.footer-text,
.contact-link,
.contact-link:visited {
  color: var(--light-color);
  display: block;
  font-family: "Rubik", sans-serif;
  font-size: 1rem;
  font-weight: 600;
}

.contact-link:hover {
  color: var(--dark-color);
  background-color: var(--light-color);
}

@media (max-width: 768px) {
  .footer-text,
  .contact-link {
    font-size: .75rem;
  }
}