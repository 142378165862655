.skill-badge {
  background-color: var(--light-color);
  color: var(--brand-color);
  display: inline-block;
  text-align: center;
  width: 100px;
  box-shadow: 0px 3px 15px rgba(0,0,0,0.1);
}

.skill-icon {
  display: block;
  font-size: 2rem;
}