/* Theme and Inherited Styles */

:root {
  --brand-color: #00B894;
  --brand-dark: hsl(168, 100%, 32%);
  --accent-dark: #5A6385;
  --accent-darker: hsl(227, 19%, 38%);
  --light-color: #EEF1F2;
  --dark-color: #1F202D;
  --roboto: "Roboto", Arial, Helvetica, sans-serif;
  --rubik: "Rubik", Arial, Helvetica, sans-serif;
  overflow-y: scroll;
}

/* Background color wrapper */

#bg-wrapper {
  background-color: var(--light-color);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

/* Typography media queries */

@media (max-width: 992px) {
  .heading {
    font-size: 2.75rem;
  }

  .h5 {
    font-size: 1.25rem;
  }
}

@media (max-width: 576px) {
  .heading {
    font-size: 2.75rem;
  }

  .nav-link {
    font-size: .8rem;
  }

  .project-description {
    font-size: .9rem;
  }

  .h5 {
    font-size: 1.175rem;
  }
}